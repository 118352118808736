import React, { Component } from 'react';
import { Button, Typography, Modal, Link, IconButton } from '@material-ui/core';
// import NavBar from "../../components/Navbar/Navbar";
import './EventLiveTimes.css';
import axios from "axios";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { DataGrid, GridColumnHeaderItem } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel";
import TouchAppIcon from '@material-ui/icons/TouchApp';
// import Footer from "../../components/Footer/Footer";
import ListSubheader from '@material-ui/core/ListSubheader';
import { Fragment } from 'react';
import { ImportContacts } from '@material-ui/icons';


const URL_API = process.env.REACT_APP_URL_API;

class EventLiveTimes extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            eventId: this.props.match.params.id ? this.props.match.params.id : null,
            eventData: {},
            showContent: false,
            stagesTimes: [],
            group: "",
            class: "",
            stage: "",
            stageName: "",
            stageName2: "",
            stageGeneral: "",
            stageGeneral2: "",
            stageKm: "",
            stageInitiate: "",
            tabValue: 0,
            showLoader: true,
            intervalId: null,
            openTimesModal: false,
            modalHeader: '',
            modalCocheDatos: '',
            modalPiloto: '',
            modalCopiloto: '',
            stageTimeColumns: [],
            generalTimeColumns: [],
            trackingColumns: [],
            gridTimeHeight: 75,
        }
    }


    handleWindowResize () {
        if (window.innerWidth >= 768) {
            this.setState({
                gridTimeHeight: 75,
            })
        } else {
            this.setState({
                gridTimeHeight: 37,
            })
        }
    }

    initWindowResize () {
        if (window.innerWidth  < 768) {
            this.setState({
                gridTimeHeight: 37,
            })
        }
    }

    
    componentDidMount() {

        this.getTimes(false)

        this.state.showContent = true
        // this.setState({
        //     showContent: true
        // })

        this.getTimesInterval()

        //Estado de ancho del windows, para utilizar en alto de datagrid
        this.initWindowResize()
        window.addEventListener('resize', this.handleWindowResize.bind(this)) 
    }


    componentWillUnmount() {
        clearInterval(this.state.intervalId)
    }

    getTimesInterval() {
        this.state.intervalId = setInterval(() => {
            this.getTimes(true)
        }, 5000);
    }





    getLiveTimes(fromInterval) {

        this.setState({
            stagesTimes: fromInterval ? this.state.stagesTimes : [],
            showLoader: fromInterval ? false : true
        })

        console.log(this.state.eventId + '  ' + this.state.group + '  ' + this.state.class + '  ' + this.state.stage)

        axios.post(`${URL_API}/live-times-list.php`).then(result => {
            let tempStageTimesColumns = []
            let tempStageTimes = []
            let columnNames = Object.keys(result.data[0])

            console.log(this.state.intervalId)

            columnNames.map((name) => {
                let column = {}
                let hidden = name.toLowerCase() == "id" || name.toLowerCase() == "auto" || name.toLowerCase() == "copilotonom" || name.toLowerCase() == "difanterior" || name.toLowerCase() == "clase" || name.toLowerCase() == "pegru" || name.toLowerCase() == "penom" || name.toLowerCase() == "penom2" || name.toLowerCase() == "pruebageneral" || name.toLowerCase() == "pruebageneral2" ? true : false
                let headerName = name

                switch (name.toLowerCase()){
                    case "difprimero":
                        // headerName = "DIF. 1ro/Ant."
                        <div className='live-time-list-dif'>
                            
                        </div>
                        column = { field: name.toLowerCase(), headerName: headerName, width: this.getStageTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden, renderCell: (params) => (
                            <div>
                                <div>
                                    <p className='live-time-difprimero'>{params.row['difprimero']}</p>
                                </div>                
                            </div>
                        )}
                        break;
                    default:
                        column = { field: name.toLowerCase(), headerName: headerName, width: this.getStageTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden }
                        break;
                }

                // column = { field: name.toLowerCase(), headerName: headerName, width: this.getStageTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden }

                tempStageTimesColumns.push(column)
            })


            result.data.map((time) => {
                let columnData = {}
                Object.keys(time).map((prop) => {
                    columnData[prop.toLowerCase()] = time[prop]
                })
                tempStageTimes.push(columnData)
            })


            this.setState({
                stageTimeColumns: tempStageTimesColumns,
                stagesTimes: tempStageTimes,
                showLoader: false,
                showContent: true,

                group: result.data[0].PEGRU,
                class: result.data[0].CLASE,
                stage: result.data[0].PENOM,
                stageName: result.data[0].PENOM,
                stageName2: result.data[0].PENOM2,
                stageGeneral: result.data[0].PRUEBAGENERAL,
                stageGeneral2: result.data[0].PRUEBAGENERAL2
            })

            // if (result.data && result.data.length == 0) {
            //     this.setState({
            //         group: '',
            //         class: '',
            //         stage: '',
            //         stageName: '',
            //         stageName2: '',
            //         stageGeneral: '',
            //         stageGeneral2: ''
            //     })  
            // }
        })
    }





    getTimes(fromInterval) {

        this.getLiveTimes(fromInterval)
    }



    getStageTimesColumnWidth(column) {
        switch (column) {
            case "id":
            case "pilotonac":
            case "copilotonac":
            case "copilotonom":
            case "difanterior":
            case "pos":
                return 50
            case "cochenumero":
                return 105
            case "cochenumero2":
            case "auto":
                return 150
            case "pilotonom":
                return 300
            case "kmh":
                return 80
            case "penalizacion":
            case "neto":
                return 175
            case "clase":
                return 130
            case "difprimero":
                return 150
            default:
                return 110
        }
    }

    


    render() {
        return (
            <div>
                {this.state.showContent &&
                    <div>

                        <div className="live-event-page-container">

                            <div className="live-result-table">

                                {/* <p className='live-stage-name-grid'>{'Grupo:   ' + this.state.group + '\n' + 'Clase/Categoría:   ' + this.state.class + '\n' + 'Tiempos en:   ' + this.state.stageName}</p> */}
                                <p className='live-title-grid'>{this.state.stageGeneral2 + ' ' + this.state.stageName + ' | ' + this.state.class}</p>

                                <div style={{ height: 'auto', width: '100%'}}>
                                    <DataGrid 
                                        rows={this.state.stagesTimes}
                                        columns={this.state.stageTimeColumns} 
                                        pageSize={200} 
                                        autoHeight={true}
                                        hideFooterPagination={true} 
                                        // loading={this.state.showLoader}
                                        disableColumnMenu={true}
                                        // onCellClick={this.handleCellClick}
                                        rowHeight={this.state.gridTimeHeight}
                                        disableSelectionOnClick={true}
                                        className={"live-stage-times-table"}
                                        
                                        columnBuffer={4}
                                        density={"compact"}
                                        headerHeight={0}
                                        hideFooter={true}
                                    />
                                </div>
                            </div>

                        </div>
                    
                    </div>
                }
            </div>
            
        );
    }
}

export default EventLiveTimes;