import React, { Component } from 'react';
import { Button, Typography, Modal, Link, IconButton } from '@material-ui/core';
// import NavBar from "../../components/Navbar/Navbar";
import './EventLivePanel.css';
import axios from "axios";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
// import { DataGrid, GridColumnHeaderItem } from '@material-ui/data-grid';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import AppBar from "@material-ui/core/AppBar";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
// import TabPanel from "../../components/TabPanel";
import TouchAppIcon from '@material-ui/icons/TouchApp';
// import Footer from "../../components/Footer/Footer";
import ListSubheader from '@material-ui/core/ListSubheader';
import { Fragment } from 'react';
import { ImportContacts } from '@material-ui/icons';


const URL_API = process.env.REACT_APP_URL_API;


class EventLive extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            eventId: this.props.match.params.id ? this.props.match.params.id : null,
            eventData: {},
            showContent: false,
            groups: [],
            classes: [],
            stages: [],
            stagesTimes: [],
            stagesGeneralTimes: [],
            generalTimes: [],
            trackingData: [],
            modalTimeData: [],
            group: "",
            class: "",
            stage: "",
            stageGeneral: "PRUEBA",
            stageName: "",
            stageName2: "",
            stageName3: "",
            stageKm: "",
            stageInitiate: "",
            tabValue: 0,
            showLoader: true,
            intervalId: null,
            openTimesModal: false,
            modalHeader: '',
            modalCocheDatos: '',
            modalPiloto: '',
            modalCopiloto: '',
            stageTimeColumns: [],
            generalTimeColumns: [],
            trackingColumns: [],
        }
    }




    
    componentDidMount() {
        this.getEvent()
        this.getGroups()
    }


    getEvent() {
        axios.post(`${URL_API}/event.php`, { eventId: this.state.eventId }).then(result => {
            this.setState({
                eventData: result.data[0],
                showContent: true
            })
        })
    }

    getGroups() {
        axios.post(`${URL_API}/group-list.php`, { eventId: this.state.eventId }).then(result => {
            let tempGroups = []
            result.data.map((groupValue) => {
                if (groupValue.GRUPO) {
                    if (!tempGroups[groupValue.ORDENUNION-1]) {
                        tempGroups[groupValue.ORDENUNION-1] = []
                    }

                    tempGroups[groupValue.ORDENUNION-1].push(groupValue)
                } else {
                    tempGroups[groupValue.ORDENUNION-1] = [groupValue]
                }
            })

            this.setState({
                groups: tempGroups,
                group: result.data[0].NOMBRE,
            })

            this.getClasses(result.data[0].NOMBRE)
        })
    }

    getClasses(group) {
        axios.post(`${URL_API}/classes-list.php`, {
            eventId: this.state.eventId,
            groupName: group
        }).then(result => {

            let tempClasses = []

            result.data.map((classValue) => {
                if (classValue.GRUPO) {
                    if (!tempClasses[classValue.ORDENUNION-1]) {
                        tempClasses[classValue.ORDENUNION-1] = []
                    }

                    tempClasses[classValue.ORDENUNION-1].push(classValue)
                } else {
                    tempClasses[classValue.ORDENUNION-1] = [classValue]
                }
            })


            this.setState({
                classes: tempClasses,
                class: result.data[0].NOMBRE
            })

            this.getStages(group)
        })
    }

    getStages(group) {
        axios.post(`${URL_API}/stage-list.php`, {
            eventId: this.state.eventId,
            groupName: group
        }).then(result => {

            let tempStages = []

            result.data.map((stageValue) => {
                if (stageValue.GRUPO) {
                    if (!tempStages[stageValue.ORDENUNION-1]) {
                        tempStages[stageValue.ORDENUNION-1] = []
                    }

                    tempStages[stageValue.ORDENUNION-1].push(stageValue)
                } else {
                    tempStages[stageValue.ORDENUNION-1] = [stageValue]
                }
            })

            this.setState({
                stages: tempStages,
                stage: result.data[0].NOMBRE,

                //DATOS PRUEBA AL CARGAR COMBO. LUEGO getStageData al seleccionar combo

            })
        })
    }


    handleSelectGroupChange = e => {
        let tempGeneralTimeColumns = this.state.generalTimeColumns
        console.log(e.target.value)

        this.setState({ 
            group: e.target.value,
        })

        this.getClasses(e.target.value)
    }

 

    setStageGeneralData = async (stageName) => {

        var stageData = await axios.post(`${URL_API}/live-panel-update.php`, { eventId: this.state.eventId, groupName: this.state.group, className: this.state.class, stageName: this.state.stage, timesGroup: this.state.stageGeneral })
        console.log("t")
    }  


    
    handleSelectClassChange = e => {
        this.setState({ class: e.target.value })

    }

    handleSelectStageChange = e => {
        this.setState({ stage: e.target.value })

    }

    handleSelectStageGeneralChange = e => {
        this.setState({ stageGeneral: e.target.value })

    }



    getStageTimesColumnWidth(column) {
        switch (column) {
            case "id":
            case "pilotonac":
            case "copilotonac":
            case "copilotonom":
            case "difanterior":
            case "pos":
                return 50
            case "cochenumero":
            case "cochenumero2":
            case "auto":
                return 150
            case "pilotonom":
                return 350
            case "kmh":
                return 80
            case "tiempo":
                return 120
            case "penalizacion":
            case "neto":
            case "clase":
                return 130
            case "difprimero":
                return 120
            default:
                return 110
            // case "id":
            // case "pilotonac":
            // case "copilotonac":
            // case "copilotonom":
            // case "difanterior":
            // case "pos":
            //     return 20
            // case "cochenumero":
            //     return 21
            // case "auto":
            //     return 60
            // case "tripulacion":
            //     return 145
            // case "kmh":
            //     return 50
            // case "tiempo":
            //     return 65
            // case "penalizacion":
            //     return 50
            // case "neto":
            //     return 80
            // case "clase":
            //     return 50
            // case "diferencia":
            //     return 55
            // default:
            //     return 80
        }
    }

    getGeneralTimesColumnWidth(column) {
        switch (column) {
            case "id":
            case "pilotonac":
            case "copilotonac":
            case "copilotonom":
            case "difanterior":
            case "pos":
                return 50
            case "cochenumero":
            case "cochenumero2":
            case "auto":
                return 150
            case "pilotonom":
                return 350
            case "kmh":
                return 80
            case "acumulado":
                return 120
            case "penalizacion":
            case "neto":
            case "clase":
                return 130
            case "difprimero":
                return 120
            default:
                return 110
            // case "id":
            // case "pilotonac":
            // case "copilotonac":
            // case "copilotonom":
            // case "difanterior":
            // case "pos":
            //     return 20
            // case "cochenumero":
            //     return 21
            // case "auto":
            //     return 60
            // case "tripulacion":
            //     return 145
            // case "kmh":
            //     return 50
            // case "acumulado":
            //     return 65
            // case "penalizacion":
            //     return 50
            // case "neto":
            //     return 80
            // case "clase":
            //     return 50
            // case "diferencia":
            //     return 55
            // default:
            //     return 80
        }
    }
    
    getTrackingDataColumnWidth(column) {
        switch (column) {
            case "id":
            case "cochenumero":
                return 300
            case "cochenumero2":
            case "cochedatos":
            case "orden_largada":
            case "pilotonom":
            case "copilotonom":
            case "categorianom":
            case "categoriagen":
            case "horarios":
                return 150
            case "info":
                 return 300
            case "posiciones":
                return 350
            default:
                return 200
            // case "id":
            // case "cochenumero":
            //     return 150
            // case "cochedatos":
            // case "orden_largada":
            // case "pilotonom":
            // case "copilotonom":
            // case "categorianom":
            // case "categoriagen":
            // case "horarios":
            //     return 80
            // case "info":
            //      return 140
            // case "posiciones":
            //     return 150
            // default:
            //     return 80
        }
    }




    render() {
        return (
            <div>
                {/* <NavBar title="DH TIMING"/> */}
                {this.state.showContent &&
                    <div>
                        <div>
                            <Typography variant="h5" align="center" className="title">
                                TIEMPOS ONLINE - CONTROL DE TRANSMISION
                            </Typography>
                            <div className="event-data">
                                {/* <div className="img">
                                    <img src={`/img/eventos/${this.state.eventId}.png`}/>
                                </div> */}
                                <Typography align="center" className="event-page-title">
                                    {this.state.eventData.ORGANIZADOR}
                                </Typography>
                                <Typography align="center" className="event-page-title2">
                                    {this.state.eventData.NOMBRE1}
                                </Typography>
                                <Typography align="center" className="event-page-title3">
                                    {this.state.eventData.NOMBRE2}
                                </Typography>
                            </div>
                        </div>

                        <div className="event-page-container">

                            <div className="filters">

                                <div className="select">
                                    <FormControl className="selectFormControl">
                                        <InputLabel id="">Grupo</InputLabel>
                                        <Select
                                            labelId=""
                                            id=""
                                            value={this.state.group}
                                            onChange={this.handleSelectGroupChange.bind(this)}
                                        >
                                        {this.state.groups.length > 0 &&
                                                this.state.groups.map((groupArray, index) => {
                                                    if (groupArray.length > 0) {
                                                        return groupArray.map((groupValue, i) => {
                                                            if (i == 0) {
                                                                return (
                                                                    [
                                                                        <ListSubheader>{groupArray[0].GRUPO}</ListSubheader>,
                                                                        <MenuItem key={groupArray[0].NOMBRE} value={groupArray[0].NOMBRE}>{groupArray[0].NOMBRE}</MenuItem>  
                                                                    ] 
                                                                )
                                                            } else {
                                                                return <MenuItem key={groupValue.NOMBRE} value={groupValue.NOMBRE}>{groupValue.NOMBRE}</MenuItem>
                                                            }
                                                        })
                                                    } else {
                                                        return <MenuItem key={groupArray[0].NOMBRE} value={groupArray[0].NOMBRE}>{groupArray[0].NOMBRE}</MenuItem>
                                                    }
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="select">
                                    <FormControl className="selectFormControl">
                                        <InputLabel id="">Clase/Categoría</InputLabel>
                                        <Select
                                            labelId=""
                                            id=""
                                            value={this.state.class}
                                            onChange={this.handleSelectClassChange.bind(this)}
                                        >
                                            {this.state.classes.length > 0 &&
                                                this.state.classes.map((classArray, index) => {
                                                    if (classArray.length > 0) {
                                                        return classArray.map((classValue, i) => {
                                                            if (i == 0) {
                                                                return (
                                                                    [
                                                                        <ListSubheader>{classArray[0].GRUPO}</ListSubheader>,
                                                                        <MenuItem key={classArray[0].NOMBRE} value={classArray[0].NOMBRE}>{classArray[0].NOMBRE}</MenuItem>  
                                                                    ] 
                                                                )
                                                            } else {
                                                                return <MenuItem key={classValue.NOMBRE} value={classValue.NOMBRE}>{classValue.NOMBRE}</MenuItem>
                                                            }
                                                        })
                                                    } else {
                                                        return <MenuItem key={classArray[0].NOMBRE} value={classArray[0].NOMBRE}>{classArray[0].NOMBRE}</MenuItem>
                                                    }
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="select">
                                    <FormControl className="selectFormControl">
                                        <InputLabel id="">Prueba</InputLabel>
                                        <Select
                                            labelId=""
                                            id=""
                                            value={this.state.stage}
                                            onChange={this.handleSelectStageChange.bind(this)}
                                        >
                                        {this.state.stages.length > 0 &&
                                            this.state.stages.map((stageArray, index) => {
                                                if (stageArray.length > 0) {
                                                    return stageArray.map((stageValue, i) => {
                                                        if (i == 0) {
                                                            return (
                                                                [
                                                                    <ListSubheader>{stageArray[0].GRUPO}</ListSubheader>,
                                                                    <MenuItem key={stageArray[0].NOMBRE} value={stageArray[0].NOMBRE}>{stageArray[0].NOMBRE2}</MenuItem>  
                                                                ] 
                                                            )
                                                        } else {
                                                            return <MenuItem key={stageValue.NOMBRE} value={stageValue.NOMBRE}>{stageValue.NOMBRE2}</MenuItem>
                                                        }
                                                    })
                                                } else {
                                                    return <MenuItem key={stageArray[0].NOMBRE} value={stageArray[0].NOMBRE}>{stageArray[0].NOMBRE2}</MenuItem>
                                                }
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </div>


                                <div className="select">
                                    
                                    <FormControl className="selectFormControl">
                                        <InputLabel id="">Tiempos</InputLabel>
                                        <Select
                                            labelId=""
                                            Id=""
                                            value={this.state.stageGeneral}
                                            onChange={this.handleSelectStageGeneralChange.bind(this)}
                                        >
                                        <MenuItem value="" disabled>
                                        </MenuItem>
                                        <MenuItem value={'PRUEBA'}>PRUEBA</MenuItem>
                                        <MenuItem value={'GENERAL'}>GENERAL</MenuItem>
                                        </Select>
                                    </FormControl>

                                </div>

                                <div className='send' style={{textAlign:'center'}}>
                                    <Button className="event-action-btn" variant="contained" color="primary" style={{marginBottom:20, fontSize:20}} onClick={this.setStageGeneralData.bind(this)}>
                                        E N V I A R
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>
                }

            </div>
            
        );
    }
}

export default EventLive;