import React, { Component } from 'react';
import { Button, Typography, Modal, Link, IconButton } from '@material-ui/core';
import NavBar from "../../components/Navbar/Navbar";
import './Event.css';
import axios from "axios";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { DataGrid, GridColumnHeaderItem } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel";
import TouchAppIcon from '@material-ui/icons/TouchApp';
import Footer from "../../components/Footer/Footer";
import ListSubheader from '@material-ui/core/ListSubheader';
import { Fragment } from 'react';
import { ImportContacts } from '@material-ui/icons';


const URL_API = process.env.REACT_APP_URL_API;


class EventNew extends Component {

    // stageTimeColumns = [
    //     { field: 'id', headerName: 'POS', width: 40, sortable: false},
    //     { field: 'auto', headerName: 'AUTO', width: 100, sortable: false },
    //     { field: 'tripulacion', headerName: 'TRIPULACION', width: 140, sortable: false },
    //     { field: 'clase', headerName: 'CLASE', width: 60, sortable: false },
    //     { field: 'tiempo', headerName: 'TIEMPO', width: 90, sortable: false },
    //     { field: 'neto', headerName: 'NETO', width: 90, sortable: false, renderCell: (params) => (
    //         <div style={{display: "flex", cursor: "pointer"}}>
    //             <Link>{params.row.neto}</Link>
    //             <TouchAppIcon fontSize={'default'}></TouchAppIcon>
    //         </div>
    //     ) },
    //     { field: 'diferencia', headerName: 'DIFERENCIA', width: 110, sortable: false },
    //     { field: 'kmh', headerName: 'KMH', width: 60, sortable: false },
    //     { field: 'coche_numero', headerName: 'coche_numero', width: 100, sortable: false, hide: true },
    //     { field: 'coche_datos', headerName: 'coche_datos', width: 100, sortable: false, hide: true },
    //     { field: 'piloto_nom', headerName: 'piloto_nom', width: 100, sortable: false, hide: true },
    //     { field: 'copiloto_nom', headerName: 'copiloto_nom', width: 100, sortable: false, hide: true },
    // ]

    // trackingColumns = [
    //     { field: 'id', headerName: 'ID', width: 100, sortable: false, hide: true },
    //     { field: 'auto', headerName: 'AUTO / TRIPULACION', width: 150, sortable: false },
    //     { field: 'horarios', headerName: 'HORARIOS', width: 90, sortable: false },
    //     { field: 'info', headerName: 'INFO', width: 180, sortable: false },
    //     { field: 'posiciones', headerName: 'POSICIONES', width: 200, sortable: false }
    // ]

    modalTimeColumns = [
        { field: 'id', headerName: 'ID', width: 0, sortable: false, hide: true },
 
        { field: 'prueba', headerName: 'PRUEBA', width: 320, sortable: false },
        { field: 'tiempo', headerName: 'TIEMPO', width: 110, sortable: false },
        { field: 'penalizacion', headerName: 'PENALIZ.', width: 160, sortable: false, renderCell: (params) => (
            <div className='modal--times--pen' >
                <p className="penalizacion">{params.row.penalizacion}</p>
                <p className="penalizacioninfo">{params.row.info2}</p>
            </div>
        ) },
        { field: 'neto', headerName: 'NETO', width: 110, sortable: false }
    ]

    constructor(props) {
        super(props)
    
        this.state = {
            eventId: this.props.match.params.id ? this.props.match.params.id : null,
            eventData: {},
            showContent: false,
            groups: [],
            classes: [],
            stages: [],
            stagesTimes: [],
            generalTimes: [],
            trackingData: [],
            modalTimeData: [],
            group: "",
            class: "",
            stage: "",
            stageName: "",
            stageName2: "",
            stageName3: "",
            stageKm: "",
            stageInitiate: "",
            tabValue: 0,
            showLoader: true,
            intervalId: null,
            openTimesModal: false,
            modalHeader: '',
            modalCocheDatos: '',
            modalPiloto: '',
            modalCopiloto: '',
            stageTimeColumns: [],
            generalTimeColumns: [],
            trackingColumns: [],
            gridTimeHeight: 75,
            gridTrackingHeight: 100,
            gridTimesModalheight: 50,
            generalTabName: "ACUMULADO"
        }

        this.getTimesInterval = this.getTimesInterval.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    }


    handleWindowResize () {
        if (window.innerWidth >= 768) {
            this.setState({
                gridTimeHeight: 75,
                gridTrackingHeight: 100,
                gridTimesModalheight: 50
            })
        } else {
            this.setState({
                gridTimeHeight: 40,
                gridTrackingHeight: 55,
                gridTimesModalheight: 30
            })
        }
        // console.log(this.state.gridTimeHeight)
    }

    initWindowResize () {
        if (window.innerWidth  < 768) {
            this.setState({
                gridTimeHeight: 40,
                gridTrackingHeight: 55,
                gridTimesModalheight: 30
            })
        }
    }

    
    componentDidMount() {
        this.getEvent()
        this.getGroups()

        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        this.getTimesInterval()

        //Estado de ancho del windows, para utilizar en alto de datagrid
        this.initWindowResize()
        window.addEventListener('resize', this.handleWindowResize.bind(this)) 
    }



    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }


    handleVisibilityChange() {
        if (document.visibilityState === 'visible') {
            this.getTimes("", null, true); // Llamar inmediatamente al volver visible
            this.getTimesInterval(); // Reiniciar el intervalo
        } else {
            clearInterval(this.state.intervalId);
            this.setState({ intervalId: null });
        }
    }



    // (01) getTimesInterval() {
    //     this.state.intervalId = setInterval(() => {
    //         this.getTimes("", null, true)
    //     }, 5000);
    // }


    getTimesInterval() {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
        const intervalId = setInterval(() => {
            this.getTimes("", null, true);
        }, 15000);
        this.setState({ intervalId });
    }



    getEvent() {
        axios.post(`${URL_API}/event.php`, { eventId: this.state.eventId }).then(result => {
            this.setState({
                eventData: result.data[0],
                showContent: true
            })
        })
    }

    getGroups() {
        axios.post(`${URL_API}/group-list.php`, { eventId: this.state.eventId }).then(result => {
            // let tempGeneralTimeColumns = this.state.generalTimeColumns

            // if (result.data[0].NOMBRE == "SHAKEDOWN" || result.data[0].NOMBRE == "PRACTICA") {
            //     tempGeneralTimeColumns.splice(4, 0, { field: 'acumulado', headerName: 'MEJOR T.', width: 150, sortable: false})
            // } else {
            //     tempGeneralTimeColumns.splice(4, 0, { field: 'acumulado', headerName: 'ACUMULADO', width: 150, sortable: false })
            // }

            let tempGroups = []

            result.data.map((groupValue) => {
                if (groupValue.GRUPO) {
                    if (!tempGroups[groupValue.ORDENUNION-1]) {
                        tempGroups[groupValue.ORDENUNION-1] = []
                    }

                    tempGroups[groupValue.ORDENUNION-1].push(groupValue)
                } else {
                    tempGroups[groupValue.ORDENUNION-1] = [groupValue]
                }
            })

            // console.log(tempGroups);

            this.setState({
                groups: tempGroups,
                group: result.data[0].NOMBRE,
                // generalTimeColumns: [
                //     ...this.state.generalTimeColumns,
                //     tempGeneralTimeColumns
                // ]
            })

            this.getClasses(result.data[0].NOMBRE)
        })
    }

    getClasses(group) {
        axios.post(`${URL_API}/classes-list.php`, {
            eventId: this.state.eventId,
            groupName: group
        }).then(result => {

            let tempClasses = []

            result.data.map((classValue) => {
                if (classValue.GRUPO) {
                    if (!tempClasses[classValue.ORDENUNION-1]) {
                        tempClasses[classValue.ORDENUNION-1] = []
                    }

                    tempClasses[classValue.ORDENUNION-1].push(classValue)
                } else {
                    tempClasses[classValue.ORDENUNION-1] = [classValue]
                }

                // TIPO DE EVENTO PARA UTILIZAR EN TAB GENERAL (RALLY, SHAKEDOWN)
                // console.log(classValue.TIPO)
                if (classValue.TIPO == "RALLY") {
                    this.setState({
                        generalTabName: "ACUMULADO"
                    })
                } else {
                    this.setState({
                        generalTabName: "MEJOR TIEMPO"
                    })
                }

            })


            this.setState({
                classes: tempClasses,
                class: result.data[0].NOMBRE
            })

            this.getStages(group)
        })
    }

    getStages(group) {
        axios.post(`${URL_API}/stage-list.php`, {
            eventId: this.state.eventId,
            groupName: group
        }).then(result => {

            let tempStages = []

            result.data.map((stageValue) => {
                if (stageValue.GRUPO) {
                    if (!tempStages[stageValue.ORDENUNION-1]) {
                        tempStages[stageValue.ORDENUNION-1] = []
                    }

                    tempStages[stageValue.ORDENUNION-1].push(stageValue)
                } else {
                    tempStages[stageValue.ORDENUNION-1] = [stageValue]
                }
            })

            this.setState({
                stages: tempStages,
                stage: result.data[0].NOMBRE,

                //DATOS PRUEBA AL CARGAR COMBO. LUEGO getStageData al seleccionar combo
                stageName: result.data[0].NOMBRE,
                stageName2: result.data[0].NOMBRE + ' | ' + result.data[0].PENOM2,
                stageName3: result.data[0].NOMBRE3,
                stageKm: result.data[0].KILOMETRO,
                stageInitiate: result.data[0].INICIADO
            })

            switch (this.state.tabValue) {
                case 0:
                    this.getStageTimes()
                    break;
                case 1:
                    this.getGeneralTimes()
                    break;
                case 2:
                    this.getTracking()
                    break;
            }

            // this.getStageData(result.data[0].NOMBRE)

        })
    }


    getStageTimes(params, fromInterval) {
        this.setState({
            stagesTimes: fromInterval ? this.state.stagesTimes : [],
            showLoader: fromInterval ? false : true
        })

        let body = params ? params : {
            eventId: this.state.eventId,
            groupName: this.state.group,
            className: this.state.class,
            stageName: this.state.stage
        }

        axios.post(`${URL_API}/stage-times-list.php`, body).then(result => {
            let tempStageTimesColumns = []
            let tempStageTimes = []
            let columnNames = Object.keys(result.data[0])


            // console.log(this.state.intervalId)


            columnNames.map((name) => {
                let column = {}
                let hidden = name.toLowerCase() == "id" || name.toLowerCase() == "cochenumero" || name.toLowerCase() == "cochenumero2" || name.toLowerCase() == "penalizacion" || name.toLowerCase() == "neto" || name.toLowerCase() == "copilotonom" || name.toLowerCase() == "pilotonac" || name.toLowerCase() == "copilotonac" || name.toLowerCase() == "difanterior" || name.toLowerCase() == "auto" ? true : false
                let headerName = name

                switch (name.toLowerCase()){
                    case "tiempo":
                        // headerName = (
                        //     <p>TIEMPO <TouchAppIcon className='touchIcon' style={{paddingTop: "2px"}}></TouchAppIcon> </p>
                        // )
                        column = { field: name.toLowerCase(), headerName: headerName, width: this.getStageTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden, renderCell: (params) => (
                            <div className='tiempo--pen--neto'>
                                <div className="tiempo">
                                    {params.row[name.toLowerCase()]}
                                </div>
                                <div className="penalizacion">
                                    {params.row['penalizacion']}
                                </div>
                                <div className="neto" style={{cursor: "pointer"}}>
                                    <Link className='link'>{params.row['neto']}<TouchAppIcon className='touchIcon'></TouchAppIcon></Link>
                                </div>
                         

                                {/* <p>{params.row[name.toLowerCase()]}</p>
                                <p>{params.row['penalizacion']}</p>
                                <Link>{params.row['neto']}</Link> */}

                                {/* <div className='neto' style={{display: "flex", cursor: "pointer"}}> */}
                                {/* <div className='neto-icon' style={{display: "flex", cursor: "pointer"}}>
                                    <div className='neto'>
                                        <Link>{params.row['neto']}</Link>
                                    </div>
                                    <div className='netoIconDiv'>
                                        <TouchAppIcon className='netoIcon'></TouchAppIcon>
                                    </div>                              
                                </div> */}
                            </div>
                        ) }
                        break;

                    case "pilotonom":
                        headerName = "TRIPULACION"
                        column = { field: name.toLowerCase(), headerName: headerName, width: this.getStageTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden, renderCell: (params) => (
                            <div className='tripulacion--bandera'>
                                {/* <div> */}
                                    {params.row['pilotonac'] ? (
                                        <p><img src={'/img/banderas/'+(params.row['pilotonac'])+'.png'}/>{params.row['pilotonom']}</p>
                                    ) : (
                                        <p><img src={'/img/varios/piloto.png'}/>{params.row['pilotonom']}</p>
                                    )}
                                {/* </div> */}
                                {/* <div> */}
                                    {params.row['copilotonac'] ? (
                                        <p><img src={'/img/banderas/'+(params.row['copilotonac'])+'.png'}/>{params.row['copilotonom']}</p>
                                    ) : (
                                        <p><img src={'/img/varios/copiloto.png'}/>{params.row['copilotonom']}</p>
                                    )}
                                {/* </div> */}
                
                                <div className='tripulacion--auto'>
                                    <p className='numero'> {params.row['cochenumero']} <span className='auto'>{params.row['auto']}</span> </p>
                                </div>
                            </div>
                        ) } 
                        break;

                    // case "auto":
                    //     column = { field: name.toLowerCase(), headerName: headerName, width: this.getStageTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden, renderCell: (params) => (
                    //         <div>
                    //             <div>
                    //                 <p className='numero'>{params.row['cochenumero']}</p>
                    //             </div>                
                    //                 <div>
                    //                 <p className='auto'>{params.row['auto']}</p>
                    //             </div>
                    //         </div>
                    //     )}
                    //     break;

                    case "difprimero":
                        headerName = "DIF. 1ro/Ant."
                        column = { field: name.toLowerCase(), headerName: headerName, width: this.getStageTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden, renderCell: (params) => (
                            <div>
                                <div>
                                    <p className='difprimero'>{params.row['difprimero']}</p>
                                </div>                
                                    <div>
                                    <p className='difanterior'>{params.row['difanterior']}</p>
                                </div>
                            </div>
                        )}
                        break;

                    case "kmh":
                        headerName = "KM/H"
                        column = { field: name.toLowerCase(), headerName: headerName, width: this.getStageTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden }
                        break;

                    default:
                        column = { field: name.toLowerCase(), headerName: headerName, width: this.getStageTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden }
                        break;
                }

                tempStageTimesColumns.push(column)
            })

            result.data.map((time) => {
                let columnData = {}
                Object.keys(time).map((prop) => {
                    columnData[prop.toLowerCase()] = time[prop]
                })

                tempStageTimes.push(columnData)

                // tempStageTimes.push({
                //     id: time.POS,
                //     auto: time.COCHENUMERO+"\n"+time.COCHEDATOS,
                //     tripulacion: time.PILOTONOM+"\n"+time.COPILOTONOM,
                //     clase: time.CLASE,
                //     tiempo: time.TIEMPO+"\n"+time.PENALIZACION,
                //     neto: time.NETO,
                //     diferencia: time.DIFPRIMERO+"\n"+time.DIFANTERIOR,
                //     kmh: time.KMH,
                //     coche_numero: time.COCHENUMERO,
                //     coche_datos: time.COCHEDATOS,
                //     piloto_nom: time.PILOTONOM,
                //     copiloto_nom: time.COPILOTONOM,
                // })
            })

            this.setState({
                stageTimeColumns: tempStageTimesColumns,
                stagesTimes: tempStageTimes,
                showLoader: false
            })
        })
    }

    

    getGeneralTimes(params, fromInterval) {
        this.setState({
            generalTimes: fromInterval ? this.state.generalTimes : [],
            showLoader: fromInterval ? false : true,
        })

        let body = params ? params : {
            eventId: this.state.eventId,
            groupName: this.state.group,
            className: this.state.class,
            stageName: this.state.stage
        }

        axios.post(`${URL_API}/general-times-list.php`, body).then(result => {
            let tempGeneralTimesColumns = []
            let tempGeneralTimes = []


            let columnNames = Object.keys(result.data[0])

            columnNames.map((name) => {
                let column = {}
                let hidden = name.toLowerCase() == "id" || name.toLowerCase() == "cochenumero" || name.toLowerCase() == "cochenumero2" || name.toLowerCase() == "penalizacion" || name.toLowerCase() == "neto" || name.toLowerCase() == "copilotonom" || name.toLowerCase() == "pilotonac" || name.toLowerCase() == "copilotonac" || name.toLowerCase() == "difanterior" || name.toLocaleLowerCase() == "auto" ? true : false
                let headerName = ""

                if (name.toLowerCase() == "acumulado") {
                    headerName = name
                    let group = body["groupName"]

                    if (group.includes("SHAKEDOWN") || group.includes("PRACTICA") || group.includes("PRUEBA")) {
                        headerName = 'MEJOR TIEMPO'
                    }
                } else {
                    headerName = name
                }

                switch (name.toLowerCase()){
                    case "acumulado":
                        // headerName = (
                        //     <p>ACUMULADO <TouchAppIcon className='touchIcon' style={{paddingTop: "2px"}}></TouchAppIcon> </p>
                        // )
                        column = { field: name.toLowerCase(), headerName: headerName, width: this.getGeneralTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden, renderCell: (params) => (
                            <div className='tiempo--pen--neto'>
                                <div className="tiempo">
                                    {params.row[name.toLowerCase()]}
                                </div>
                                <div className="penalizacion">
                                    {params.row['penalizacion']}
                                </div>
                                <div className="neto" style={{cursor: "pointer"}}>
                                    <Link className='link'>{params.row['neto']}<TouchAppIcon className='touchIcon'></TouchAppIcon></Link>
                                </div>
                            </div>
                        ) }
                        break;

                    case "pilotonom":
                        headerName = "TRIPULACION"    
                        column = { field: name.toLowerCase(), headerName: headerName, width: this.getGeneralTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden, renderCell: (params) => (
                            <div className='tripulacion--bandera'>
                                {/* <div> */}
                                    {params.row['pilotonac'] ? (
                                        <p><img src={'/img/banderas/'+(params.row['pilotonac'])+'.png'}/>{params.row['pilotonom']}</p>
                                    ) : (
                                        <p><img src={'/img/varios/piloto.png'}/>{params.row['pilotonom']}</p>
                                    )}
                                {/* </div> */}
                                {/* <div> */}
                                    {params.row['copilotonac'] ? (
                                        <p><img src={'/img/banderas/'+(params.row['copilotonac'])+'.png'}/>{params.row['copilotonom']}</p>
                                    ) : (
                                        <p><img src={'/img/varios/copiloto.png'}/>{params.row['copilotonom']}</p>
                                    )}
                                {/* </div> */}
                                <div className='tripulacion--auto'>
                                    <p className='numero'> {params.row['cochenumero']} <span className='auto'>{params.row['auto']}</span> </p>
                                </div>
                            </div>
                        ) } 
                        break;

                    // case "auto":
                    //     column = { field: name.toLowerCase(), headerName: headerName, width: this.getGeneralTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden, renderCell: (params) => (
                    //         <div>
                    //             <div>
                    //                 <p className='numero'>{params.row['cochenumero']}</p>
                    //             </div>                
                    //                 <div>
                    //                 <p className='auto'>{params.row['auto']}</p>
                    //             </div>
                    //         </div>
                    //     )}
                    //     break;

                        case "difprimero":
                            headerName = "DIF. 1ro/Ant."
                            column = { field: name.toLowerCase(), headerName: headerName, width: this.getGeneralTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden, renderCell: (params) => (
                                <div>
                                    <div>
                                        <p className='difprimero'>{params.row['difprimero']}</p>
                                    </div>                
                                        <div>
                                        <p className='difanterior'>{params.row['difanterior']}</p>
                                    </div>
                                </div>
                            )}
                            break;

                    default:
                        column = { field: name.toLowerCase(), headerName: headerName, width: this.getGeneralTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden }
                        break;
                }

                tempGeneralTimesColumns.push(column)
            })

            result.data.map((time) => {

                let columnData = {}
                Object.keys(time).map((prop) => {
                    columnData[prop.toLowerCase()] = time[prop]
                })

                tempGeneralTimes.push(columnData)
            })

            this.setState({
                generalTimeColumns: tempGeneralTimesColumns,
                generalTimes: tempGeneralTimes,
                showLoader: false
            })
        })
    }



    getTracking(params, fromInterval) {
        this.setState({
            trackingData: fromInterval ? this.state.trackingData : [],
            showLoader: fromInterval ? false : true
        })

        let body = params ? params : {
            eventId: this.state.eventId,
            groupName: this.state.group,
            className: this.state.class,
            stageName: this.state.stage
        }

        axios.post(`${URL_API}/tracking.php`, body).then(result => {
            let tempTrackingColumns = []
            let tempTrackingData = []
            let columnNames = Object.keys(result.data[0])
            // let cont = 1

            columnNames.map((name) => {
                let column = {}
                let hidden = name.toLowerCase() == "id" || name.toLowerCase() == "cochenumero2" || name.toLowerCase() == "cochedatos" || name.toLowerCase() == "orden_largada" || name.toLowerCase() == "pilotonom" || name.toLowerCase() == "copilotonom" || name.toLowerCase() == "categorianom" || name.toLowerCase() == "categoriagen" || name.toLowerCase() == "llegada" || name.toLowerCase() == "penalizacion" || name.toLowerCase() == "neto" ? true : false
                let headerName = name

                switch (name.toLowerCase()){
                    case "cochenumero":
                        headerName = "AUTO/TRIPULACION"
                        column = { field: name.toLowerCase(), headerName: headerName, width: this.getTrackingDataColumnWidth(name.toLowerCase()), sortable: false, hide: hidden, renderCell: (params) => (
                        <div>
                            <div>
                                <p className='piloto--nombre'>{params.row['pilotonom']}</p>
                            </div>
                            <div>
                                <p className='copiloto--nombre'>{params.row['copilotonom']}</p>
                            </div>     
                            <div style={{display: "flex", alignItems: "center"}}>
                                <p className='coche--numero'>{params.row['cochenumero']+" "}</p>
                                <p className='coche--datos'>{params.row['cochedatos']}</p>
                            </div>     
                            <div style={{display: "flex"}}>
                                <p className='categoria--nombre'>{params.row['categorianom']+"     "}</p>
                                <p className='orden--largada'>{params.row['orden_largada']}</p>
                            </div>
                        </div>
                        ) }
                        break;

                    default:
                        column = { field: name.toLowerCase(), headerName: headerName, width: this.getTrackingDataColumnWidth(name.toLowerCase()), sortable: false, hide: hidden }
                        break;
                }

                tempTrackingColumns.push(column)
                
            })

                result.data.map((tracking) => {
                    let columnData = {}
                    Object.keys(tracking).map((prop) => {
                    columnData[prop.toLowerCase()] = tracking[prop]
                })

                tempTrackingData.push(columnData)
            })

            this.setState({
                trackingColumns: tempTrackingColumns,
                trackingData: tempTrackingData,
                showLoader: false
            })
        })
    }



    getTimes(select, value, fromInterval) {
        let params = {}

        switch (select) {
            case "group":
                params = {
                    eventId: this.state.eventId,
                    groupName: value,
                    className: this.state.class,
                    stageName: this.state.stage
                }

                break;

            case "class":
                params = {
                    eventId: this.state.eventId,
                    groupName: this.state.group,
                    className: value,
                    stageName: this.state.stage
                }

                break;

            case "stage":
                params = {
                    eventId: this.state.eventId,
                    groupName: this.state.group,
                    className: this.state.class,
                    stageName: value
                }

                break;
            default:
                params = {
                    eventId: this.state.eventId,
                    groupName: this.state.group,
                    className: this.state.class,
                    stageName: this.state.stage
                }

                break;
        }

        switch (this.state.tabValue) {
            case 0:
                this.getStageTimes(params, fromInterval)
                break;
            case 1:
                this.getGeneralTimes(params, fromInterval)
                break;
            case 2:
                this.getTracking(params, fromInterval)
                break;
        }

        // axios.post(`${URL_API}/stage-times-list.php`, body).then(result => {
        //     let tempStageTimes = []

        //     result.data.map((time) => {
        //         tempStageTimes.push({
        //             id: time.POS,
        //             auto: time.COCHENUMERO+"\n"+time.COCHEDATOS,
        //             tripulacion: time.PILOTONOM+"\n"+time.COPILOTONOM,
        //             clase: time.CLASE,
        //             tiempo: time.TIEMPO+"\n"+time.PENALIZACION,
        //             neto: time.NETO,
        //             diferencia: time.DIFPRIMERO+"\n"+time.DIFANTERIOR,
        //             kmh: time.KMH,
        //             coche_numero: time.COCHENUMERO,
        //             coche_datos: time.COCHEDATOS,
        //             piloto_nom: time.PILOTONOM,
        //             copiloto_nom: time.COPILOTONOM,
        //         })
        //     })

        //     this.setState({stagesTimes: tempStageTimes})

        //     axios.post(`${URL_API}/general-times-list.php`, body).then(result => {
        //         let tempGeneralTimesColumns = []
        //         let tempGeneralTimes = []

        //         let columnNames = Object.keys(result.data[0])

        //         columnNames.map((name) => {
        //             let column = {}
        //             let hidden = name.toLowerCase() == "id" ? true : false
        //             let headerName = ""

        //             if (name.toLowerCase() == "acumulado") {
        //                 headerName = name

        //                 if (body["groupName"] == "SHAKEDOWN" || body["groupName"] == "PRACTICA" || body["groupName"] == "PRUEBA") {
        //                     headerName = 'MEJOR TIE.'
        //                 }
        //             } else {
        //                 headerName = name
        //             }

        //             if (name.toLowerCase() == "neto") {
        //                 column = { field: name.toLowerCase(), headerName: headerName, width: this.getGeneralTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden, renderCell: (params) => (
        //                             <div style={{display: "flex", cursor: "pointer"}}>
        //                                 <Link>{params.row[name.toLowerCase()]}</Link>
        //                                 <TouchAppIcon fontSize={'default'}></TouchAppIcon>
        //                             </div>
        //                         ) }
        //             } else {
        //                 column = { field: name.toLowerCase(), headerName: headerName, width: this.getGeneralTimesColumnWidth(name.toLowerCase()), sortable: false, hide: hidden }
        //             }

        //             tempGeneralTimesColumns.push(column)
        //         })

        //         console.log("COLUMN NAMES", tempGeneralTimesColumns)

        //         result.data.map((time, index) => {

        //             let columnData = {}
        //             Object.keys(time).map((prop) => {
        //                 columnData[prop.toLowerCase()] = time[prop]
        //             })

        //             tempGeneralTimes.push(columnData)

        //             // tempGeneralTimes.push({
        //             //     id: time.POS,
        //             //     auto: time.COCHENUMERO+"\n"+time.COCHEDATOS,
        //             //     tripulacion: time.PILOTONOM+"\n"+time.COPILOTONOM,
        //             //     clase: time.CLASE,
        //             //     acumulado: time.ACUMULADO+"\n"+time.PENALIZACION,
        //             //     neto: time.NETO,
        //             //     diferencia: time.DIFPRIMERO+"\n"+time.DIFANTERIOR,
        //             //     coche_numero: time.COCHENUMERO,
        //             //     coche_datos: time.COCHEDATOS,
        //             //     piloto_nom: time.PILOTONOM,
        //             //     copiloto_nom: time.COPILOTONOM,
        //             // })
        //         })

        //         console.log("GENERAL TIMES", tempGeneralTimes)
        //         this.setState({
        //             generalTimeColumns: tempGeneralTimesColumns,
        //             generalTimes: tempGeneralTimes
        //         })

        //         this.getTracking(body)
        //     })
        // })
    }

    handleSelectGroupChange = e => {
        let tempGeneralTimeColumns = this.state.generalTimeColumns
        console.log(e.target.value)

        // if (e.target.value == "SHAKEDOWN" || e.target.value == "PRACTICA") {
        //     tempGeneralTimeColumns.splice(4, 1, { field: 'acumulado', headerName: 'MEJOR T.', width: 150 })
        // } else {
        //     tempGeneralTimeColumns.splice(4, 1, { field: 'acumulado', headerName: 'ACUMULADO', width: 150 })
        // }

        this.setState({ 
            group: e.target.value,
            // generalTimeColumns: [
            //     ...this.state.generalTimeColumns,
            //     tempGeneralTimeColumns
            // ]
        })

        this.getClasses(e.target.value)
    }

 

    getStageData = async (stageName) => {
        var stageData = await axios.post(`${URL_API}/get-stage-data.php`, { eventId: this.state.eventId, groupName: this.state.group, stageName: stageName })
        this.setState({ 
            stageName: stageData.data[0].PENOM,
            stageName2: stageData.data[0].PENOM + ' | ' + stageData.data[0].PENOM2,
            stageName3: stageData.data[0].PENOM3,
            stageKm: stageData.data[0].KILOMETRO,
            stageInitiate: stageData.data[0].INICIADO
        })
    }



    getModalTimes = (body) => {
        this.setState({ openTimesModal: true })

        axios.post(`${URL_API}/all-stage-times.php`, body).then(result => {

            let tempModalTimeData = []

            result.data.map((time) => {
                tempModalTimeData.push({
                    id: time.ID,
                    info2: time.INFO2,
                    prueba: time.PENOM,
                    tiempo: time.TIEMPO,
                    penalizacion: time.PENALIZACION,
                    neto: time.NETO
                })
            })

            this.setState({
                modalTimeData: tempModalTimeData
            })

        })
    }
    
    handleSelectClassChange = e => {
        this.setState({ class: e.target.value })
        this.getTimes("class", e.target.value, false)
    }

    handleSelectStageChange = e => {
        this.setState({ stage: e.target.value })
        this.getStageData(e.target.value)
        this.getTimes("stage", e.target.value, false)
    }

    handleTabsChange = (e, newValue) => {
        this.setState({ tabValue: newValue })
        
        switch (newValue) {
            case 0:
                this.getStageTimes()
                break;
            case 1:
                this.getGeneralTimes()
                break;
            case 2:
                this.getTracking()
                break;
        }
    }

    handleCellClick = (params, event) => {
        let body = {}
        let cocheNumero = ""

        if (params.field == "tiempo" || params.field == "acumulado" ) {

            cocheNumero = params.row.cochenumero2
            
            this.setState({
                modalHeader: params.row.cochenumero + "   " + params.row.auto + "\n" + params.row.pilotonom + "\n" + params.row.copilotonom
            })

            body = {
                eventId: this.state.eventId,
                groupName: this.state.group,
                stageName: this.state.stage,
                carNumber: cocheNumero
            }   

            this.getModalTimes(body)
        }
    }

    handleModalClose = () => {
        this.setState({
            openTimesModal: false,
            modalTimeData: []
        })
    }

    getStageTimesColumnWidth(column) {
        switch (column) {
            case "id":
            case "pilotonac":
            case "copilotonac":
            case "copilotonom":
            case "difanterior":
            case "pos":
                return 50
            case "cochenumero":
            case "cochenumero2":
            case "auto":
                return 150
            case "pilotonom":
                return 350
            case "kmh":
                return 80
            case "tiempo":
                return 120
            case "penalizacion":
            case "neto":
            case "clase":
                return 130
            case "difprimero":
                return 120
            default:
                return 110
            // case "id":
            // case "pilotonac":
            // case "copilotonac":
            // case "copilotonom":
            // case "difanterior":
            // case "pos":
            //     return 20
            // case "cochenumero":
            //     return 21
            // case "auto":
            //     return 60
            // case "tripulacion":
            //     return 145
            // case "kmh":
            //     return 50
            // case "tiempo":
            //     return 65
            // case "penalizacion":
            //     return 50
            // case "neto":
            //     return 80
            // case "clase":
            //     return 50
            // case "diferencia":
            //     return 55
            // default:
            //     return 80
        }
    }

    getGeneralTimesColumnWidth(column) {
        switch (column) {
            case "id":
            case "pilotonac":
            case "copilotonac":
            case "copilotonom":
            case "difanterior":
            case "pos":
                return 50
            case "cochenumero":
            case "cochenumero2":
            case "auto":
                return 150
            case "pilotonom":
                return 350
            case "kmh":
                return 80
            case "acumulado":
                return 120
            case "penalizacion":
            case "neto":
            case "clase":
                return 130
            case "difprimero":
                return 120
            default:
                return 110
            // case "id":
            // case "pilotonac":
            // case "copilotonac":
            // case "copilotonom":
            // case "difanterior":
            // case "pos":
            //     return 20
            // case "cochenumero":
            //     return 21
            // case "auto":
            //     return 60
            // case "tripulacion":
            //     return 145
            // case "kmh":
            //     return 50
            // case "acumulado":
            //     return 65
            // case "penalizacion":
            //     return 50
            // case "neto":
            //     return 80
            // case "clase":
            //     return 50
            // case "diferencia":
            //     return 55
            // default:
            //     return 80
        }
    }
    
    getTrackingDataColumnWidth(column) {
        switch (column) {
            case "id":
            case "cochenumero":
                return 300
            case "cochenumero2":
            case "cochedatos":
            case "orden_largada":
            case "pilotonom":
            case "copilotonom":
            case "categorianom":
            case "categoriagen":
            case "horarios":
                return 150
            case "info":
                 return 300
            case "posiciones":
                return 350
            default:
                return 200
            // case "id":
            // case "cochenumero":
            //     return 150
            // case "cochedatos":
            // case "orden_largada":
            // case "pilotonom":
            // case "copilotonom":
            // case "categorianom":
            // case "categoriagen":
            // case "horarios":
            //     return 80
            // case "info":
            //      return 140
            // case "posiciones":
            //     return 150
            // default:
            //     return 80
        }
    }




    render() {
        return (
            <div>
                <NavBar title="DH TIMING"/>
                {this.state.showContent &&
                    <div>
                        <div>
                            <Typography variant="h5" align="center" className="title">
                                TIEMPOS ONLINE
                            </Typography>
                            <div className="event-data">
                                <div className="img">
                                    <img src={`/img/eventos/${this.state.eventId}.png`}/>
                                </div>
                                <Typography align="center" className="event-page-title">
                                    {this.state.eventData.ORGANIZADOR}
                                </Typography>
                                <Typography align="center" className="event-page-title2">
                                    {this.state.eventData.NOMBRE1}
                                </Typography>
                                <Typography align="center" className="event-page-title3">
                                    {this.state.eventData.NOMBRE2}
                                </Typography>
                            </div>
                        </div>

                        <div className="event-page-container">
                            <div className="filters">
                                <div className="select">
                                    <FormControl className="selectFormControl">
                                        <InputLabel id="">Grupo</InputLabel>
                                        <Select
                                            labelId=""
                                            id=""
                                            value={this.state.group}
                                            onChange={this.handleSelectGroupChange.bind(this)}
                                        >
                                        {this.state.groups.length > 0 &&
                                                this.state.groups.map((groupArray, index) => {
                                                    if (groupArray.length > 0) {
                                                        return groupArray.map((groupValue, i) => {
                                                            if (i == 0) {
                                                                return (
                                                                    [
                                                                        <ListSubheader>{groupArray[0].GRUPO}</ListSubheader>,
                                                                        <MenuItem key={groupArray[0].NOMBRE} value={groupArray[0].NOMBRE}>{groupArray[0].NOMBRE}</MenuItem>  
                                                                    ] 
                                                                )
                                                            } else {
                                                                return <MenuItem key={groupValue.NOMBRE} value={groupValue.NOMBRE}>{groupValue.NOMBRE}</MenuItem>
                                                            }
                                                        })
                                                    } else {
                                                        return <MenuItem key={groupArray[0].NOMBRE} value={groupArray[0].NOMBRE}>{groupArray[0].NOMBRE}</MenuItem>
                                                    }
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="select">
                                    <FormControl className="selectFormControl">
                                        <InputLabel id="">Clase/Categoría</InputLabel>
                                        <Select
                                            labelId=""
                                            id=""
                                            value={this.state.class}
                                            onChange={this.handleSelectClassChange.bind(this)}
                                        >
                                            {this.state.classes.length > 0 &&
                                                this.state.classes.map((classArray, index) => {
                                                    if (classArray.length > 0) {
                                                        return classArray.map((classValue, i) => {
                                                            if (i == 0) {
                                                                return (
                                                                    [
                                                                        <ListSubheader>{classArray[0].GRUPO}</ListSubheader>,
                                                                        <MenuItem key={classArray[0].NOMBRE} value={classArray[0].NOMBRE}>{classArray[0].NOMBRE}</MenuItem>  
                                                                    ] 
                                                                )
                                                            } else {
                                                                return <MenuItem key={classValue.NOMBRE} value={classValue.NOMBRE}>{classValue.NOMBRE}</MenuItem>
                                                            }
                                                        })
                                                    } else {
                                                        return <MenuItem key={classArray[0].NOMBRE} value={classArray[0].NOMBRE}>{classArray[0].NOMBRE}</MenuItem>
                                                    }
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="select">
                                    <FormControl className="selectFormControl">
                                        <InputLabel id="">Prueba</InputLabel>
                                        <Select
                                            labelId=""
                                            id=""
                                            value={this.state.stage}
                                            onChange={this.handleSelectStageChange.bind(this)}
                                        >
                                        {this.state.stages.length > 0 &&
                                            this.state.stages.map((stageArray, index) => {
                                                if (stageArray.length > 0) {
                                                    return stageArray.map((stageValue, i) => {
                                                        if (i == 0) {
                                                            return (
                                                                [
                                                                    <ListSubheader>{stageArray[0].GRUPO}</ListSubheader>,
                                                                    <MenuItem key={stageArray[0].NOMBRE} value={stageArray[0].NOMBRE}>{stageArray[0].NOMBRE2}</MenuItem>  
                                                                ] 
                                                            )
                                                        } else {
                                                            return <MenuItem key={stageValue.NOMBRE} value={stageValue.NOMBRE}>{stageValue.NOMBRE2}</MenuItem>
                                                        }
                                                    })
                                                } else {
                                                    return <MenuItem key={stageArray[0].NOMBRE} value={stageArray[0].NOMBRE}>{stageArray[0].NOMBRE2}</MenuItem>
                                                }
                                            })
                                        }
                                        </Select>
                                    </FormControl>

                                    {/* <div className="stage-name">
                                        <span>{ this.state.stageName }</span>
                                    </div> */}

                                </div>

                                {/* {this.state.showLoader &&
                                    <div className="loader">
                                        <LinearProgress />
                                    </div>
                                } */}

                            </div>
                            
                            <div className="times-tabs">

                                <AppBar position="static">
                                    <Tabs
                                        value={this.state.tabValue}
                                        onChange={this.handleTabsChange}
                                        aria-label="simple tabs example"
                                        variant="fullWidth"
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#2ad7f6",
                                                padding: 3,
                                            }
                                        }}
                                    >
                                        {/* <Tab label={`TIEMPOS EN ${this.state.stage}`} id="simple-tab-0"/>
                                        <Tab label={`GENERAL HASTA ${this.state.stage}`} id="simple-tab-1"/>
                                        <Tab label={`SEGUIMIENTO EN ${this.state.stage}`} id="simple-tab-2"/> */}
                                        {/* <Tab label={`TIEMPOS EN:`} id="simple-tab-0"/>
                                        <Tab label={`GENERAL HASTA:`} id="simple-tab-1"/>
                                        <Tab label={`SEGUIMIENTO EN:`} id="simple-tab-2"/> */}
                                        <Tab label={`PRUEBA`} id="simple-tab-0"/>
                                        {/* <Tab label={`ACUMULADO`} id="simple-tab-1"/> */}
                                        <Tab label={this.state.generalTabName} id="simple-tab-1"/>
                                        <Tab label={`SEGUIMIENTO`} id="simple-tab-2"/>
                                    </Tabs>
                                </AppBar>

                                {/* PESTANHA "TIEMPOS PRUEBA" */}
                                <TabPanel value={this.state.tabValue} index={0}>
                                    <div className="result-table">

                                        {/* <p className='stage-name-grid'>{this.state.stageName2 + '  (' + this.state.stageKm + ' Km)'}</p> */}
                                        {/* <p className='stage-name-grid'>{this.state.stageName3}</p> */}
                                        <p className='stage-name-grid'>{'GRUPO:     ' + this.state.group + '\n' + 'CLASE/CATEG.:     ' + this.state.class + '\n' + 'TIEMPOS EN:     ' + this.state.stageName3}</p>

                                        <div style={{ height: "auto", width: '100%'}}>
                                            <DataGrid 
                                                rows={this.state.stagesTimes}
                                                columns={this.state.stageTimeColumns} 
                                                pageSize={100} 
                                                autoHeight={true}
                                                hideFooterPagination={true} 
                                                loading={this.state.showLoader}
                                                disableColumnMenu={true}
                                                onCellClick={this.handleCellClick}
                                                rowHeight={this.state.gridTimeHeight}
                                                disableSelectionOnClick={true}
                                                className={"stage-times-table"}
                                                columnBuffer={5}
                                                // density={"compact"}
                                                headerHeight={30}

                                                

                                                

                                                hideFooter={false}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>

                                {/* PESTANHA "TIEMPOS GENERAL" */}
                                <TabPanel value={this.state.tabValue} index={1}>
                                    <div className="result-table">

                                        {/* <p className='stage-name-grid'>{this.state.stageName2}</p> */}
                                        <p className='stage-name-grid'>{'GRUPO:     ' + this.state.group + '\n' + 'CLASE/CATEG.:     ' + this.state.class + '\n' + 'GENERAL HASTA:     ' + this.state.stageName2}</p>

                                        <div style={{ height: "auto", width: '100%' }}>
                                            <DataGrid 
                                                rows={this.state.generalTimes} 
                                                columns={this.state.generalTimeColumns} 
                                                pageSize={100} 
                                                autoHeight={true} 
                                                hideFooterPagination={true} 
                                                loading={this.state.showLoader}
                                                disableColumnMenu={true}
                                                onCellClick={this.handleCellClick}
                                                rowHeight={this.state.gridTimeHeight}
                                                disableSelectionOnClick={true}
                                                className={"general-times-table"}
                                                columnBuffer={8}
                                                // density={"compact"}
                                                headerHeight={30}
                                                hideFooter={false}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>

                                {/* PESTANHA "SEGUIMIENTO" */}
                                <TabPanel value={this.state.tabValue} index={2}>
                                    <div className="result-table">

                                        {/* <p className='stage-name-grid'>{this.state.stageName2}</p> */}
                                        <p className='stage-name-grid'>{'GRUPO:     ' + this.state.group + '\n' + 'CLASE/CATEG.:     ' + this.state.class + '\n' + 'SEGUIMIENTO EN:     ' + this.state.stageName2}</p>

                                        <div style={{ height: "auto", width: '100%' }}>
                                            <DataGrid 
                                                rows={this.state.trackingData} 
                                                columns={this.state.trackingColumns} 
                                                pageSize={100} 
                                                autoHeight={true} 
                                                hideFooterPagination={true} 
                                                loading={this.state.showLoader}
                                                disableColumnMenu={true}
                                                onCellClick={this.handleCellClick}
                                                rowHeight={this.state.gridTrackingHeight}
                                                disableSelectionOnClick={true}
                                                className={"tracking-table"}
                                                columnBuffer={5}
                                                // density={"compact"}
                                                headerHeight={30}
                                                hideFooter={false}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                            </div>
                        </div>
                        <div>
                            <Modal
                                open={this.state.openTimesModal}
                                onClose={this.handleModalClose}
                                aria-labelledby=""
                                aria-describedby=""
                            >
                                <div className="times-modal-content">
                                    <div className="modal-close">
                                        <span onClick={this.handleModalClose}>X</span>
                                    </div>
                                    <div className="modal-header">
                                        <p>{this.state.modalHeader}</p>
                                        {/* <p>{this.state.modalCocheDatos}</p>
                                        <p>{this.state.modalPiloto}</p>
                                        <p>{this.state.modalCopiloto}</p> */}
                                    </div>
                                    <div className="modal-table">
                                        <DataGrid 
                                            rows={this.state.modalTimeData} 
                                            columns={this.modalTimeColumns} 
                                            pageSize={100} 
                                            autoHeight={false} 
                                            hideFooterPagination={true}
                                            disableColumnMenu={true}
                                            className={"modal-grid"}
                                            columnBuffer={5}
                                            rowHeight={this.state.gridTimesModalheight}
                                            // density={"standard"}
                                        />
                                    </div>
                                    
                                </div>
                            </Modal>
                        </div>
                    </div>
                }
                <Footer/>
            </div>
            
        );
    }
}

export default EventNew;