import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Home from "./pages/Home/Home";
//import Event from "./pages/Event/Event";
import Event from "./pages/Event/EventNew";
import EventLivePanel from "./pages/Event/EventLivePanel";
import EventLiveTimes from './pages/Event/EventLiveTimes';
//import Publicidad1 from "./components/Banner/AuspiciantesApp";
//import "./components/Banner/Auspiciantes.css"

function App() {
  return (

    


    <BrowserRouter>

      {/* <div>
        <Publicidad1/>
      </div> */}

      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/event/:id' component={Event} exact />
        <Route path='/eventlivepanel/:id' component={EventLivePanel} exact />
        <Route path='/eventlivetimes/:id' component={EventLiveTimes} exact />
      </Switch>
    </BrowserRouter>

  );
}

export default App;
